import React from "react";
import styled from "styled-components";

const DetailScope = styled.div`
  margin: 20px 0 10px;
  padding: 12px;
  /* margin: 20px auto; */
  display: flex;
  flex-direction: column;
  align-items: center;
  svg {
    color: ${({ theme }) => theme.colors.gold};
    width: 40px;
  }
  h3 {
    margin: 20px 0 30px;
    padding: 0;
    position: relative;
    &:after {
      position: absolute;
      content: "";
      bottom: -10px;
      left: 0;
      right: 0;
      width: 90%;
      align: center;
      margin: 0 auto;
      height: 2px;
      background-color: ${({ theme }) => theme.colors.gold};
    }
  }
  ul {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 0;
    padding: 0;
    li {
      margin: 5px 0;
    }
  }
  @media screen and (max-width: 500px) {
    h3 {
      font-size: ${({ theme }) => theme.fontSize.m};
    }
  }
`;

const ServiceDetailPost = ({ icon, id, title, tekst }) => (
  <DetailScope id={id}>
    {icon}
    <h3>{title}</h3>
    <ul>
      {tekst.map((i) => (
        <li key={i}>{i}</li>
      ))}
    </ul>
  </DetailScope>
);

export default ServiceDetailPost;
