import React from "react";
import MainTemplate from "templates/MainTemplate";
import ServiceDetailPost from "components/ServicesDetail/ServicesDetail";
import styled from "styled-components";
import Icon1 from "assets/icons/scope/Aa.inline.svg";
import Icon2 from "assets/icons/scope/Bb.inline.svg";
import Icon3 from "assets/icons/scope/Cc.inline.svg";
import Icon4 from "assets/icons/scope/Dd.inline.svg";
import Icon5 from "assets/icons/scope/Ee.inline.svg";
import Icon6 from "assets/icons/scope/Ff.inline.svg";
import photo from "assets/pictures/adwAdriannaFirlej.jpg";
import ContactItems from "sections/TopContact/ContactItems";
import { Helmet } from "react-helmet";

const Background = styled.div`
  width: 100%;
  position: relative;
  &:after {
    content: "";
    filter: blur(30px);
    background-color: ${({ theme }) => theme.colors.grey};
    z-index: -100;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }
  @media screen and (max-width: 500px) {
  }
`;
const About = styled.h3`
  width: 90%;
  max-width: 1100px;
  margin: 40px auto 20px;
  padding: 30px 0;
  text-align: justify;
  color: ${({ theme }) => theme.colors.darkGrey};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  font-size: ${({ theme }) => theme.fontSize.xl};
  @media screen and (max-width: 900px) {
    text-align: center;
    font-size: ${({ theme }) => theme.fontSize.l};
  }
`;
const Service = styled.div`
  width: 90%;
  max-width: 1100px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  @media screen and (max-width: 900px) {
    flex-direction: column;
    ul {
      padding: 0;
    }
  }
`;

const OnePost = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0 45px;
  color: ${({ theme }) => theme.colors.darkGrey};
  @media screen and (max-width: 900px) {
    margin: 0;
  }
`;

const serviceDetailL = [
  {
    id: "prawo-karne",
    icon: <Icon1 />,
    title: "Prawo Karne",
    tekst: [
      "obrona podejrzanego w postępowaniu przygotowawczym",
      "obrona oskarżonego w postępowaniu sądowym",
      "obrona obwinionego w sprawach o wykroczenia",
      "obrona w sprawach o przestępstwa i wykroczenia skarbowe",
      "reprezentowanie pokrzywdzonego i oskarżyciela posiłkowego",
      "zawiadomienia o możliwości popełnienia przestępstwa",
      "prywatne akty oskarżenia",
      "odroczenie wykonania kary",
      "warunkowe przedterminowe zwolnienie z odbycia reszty kary",
      "odbywanie kary pozbawienia wolności w systemie dozoru elektronicznego",
      "przerwa w odbywaniu kary pozbawienia wolności",
    ],
  },
  {
    id: "prawo-rodzinne",
    icon: <Icon3 />,
    title: "Prawo Rodzinne",
    tekst: [
      "rozwód, separacja",
      "władza rodzicielska, kontakty z dzieckiem",
      "alimenty",
      "ustalenie i zaprzeczenie ojcostwa",
      "podział majątku",
      "ubezwłasnowolnienie",
    ],
  },
  {
    id: "obsluga-prawna-przedsiebiorcow",
    icon: <Icon5 />,
    title: "Obsługa prawna przedsiębiorców",
    tekst: [
      "obsługa spółek",
      "odzyskiwanie należności – wezwania do zapłaty",
      "projekty  i weryfikacja umów",
      "opinie prawne",
      "porady prawne",
      "negocjacje i mediacje z kontrahentami",
      "zastępstwo procesowe",
    ],
  },
];
const serviceDetailR = [
  {
    id: "prawo-cywilne",
    icon: <Icon2 />,
    title: "Prawo Cywilne",
    tekst: [
      "odszkodowania i zadośćuczynienia",
      "rękojmia i gwarancja",
      "odzyskiwanie należności",
      "zadośćuczynienia",
      "dochodzenie roszczeń związanych z wypadkami komunikacyjnymi",
      "projekty umów i roszczenia wynikające z umów",
      "zasiedzenie",
      "postępowanie egzekucyjne (wszczęcie egzekucji, skarga na czynności komornika)",
      "ochrona własności",
      "ochrona dóbr osobistych",
      "droga konieczna i inne służebności",
      "powództwa o zapłatę",
      "windykacja",
      "sprawy majątkowe",
      "sprawy spadkowe (stwierdzenie nabycia spadku, dział spadku, zachowek)",
      "ugodowe rozwiązywanie sporów (mediacje, negocjacje)",
    ],
  },
  {
    id: "prawo-administracyjne",
    icon: <Icon4 />,
    title: "Prawo Administracyjne",
    tekst: [
      "odwołanie od decyzji organów I instancji",
      "skargi do wojewódzkich sądów administracyjnych oraz skarg kasacyjnych do Naczelnego Sądu Administracyjnego",
      "wnioski o wznowienie postępowania, o uchylenie decyzji organu lub o stwierdzenie nieważności decyzji",
      "postępowania dyscyplinarne",
    ],
  },
  {
    id: "stala-obsluga-prawna",
    icon: <Icon6 />,
    title: "Stała obsługa prawna",
    tekst: [
      "profesjonalna i kompleksowa opieka prawna na wyciągnięcie ręki, do dyspozycji w uzgodnionych dniach i godzinach",
      "indywidualnie ustalony zakres usług",
      "optymalizacja kosztów obsługi prawnej",
    ],
  },
];

const Wrapper = styled.div`
  width: 90%;
  max-width: 1100px;
  margin: 80px auto;
  padding-bottom: 80px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 60px;
  h3 {
    font-size: ${({ theme }) => theme.fontSize.xl};
    position: relative;
    &:before {
      position: absolute;
      content: "";
      width: 2px;
      height: 150%;
      left: -20px;
      top: -25%;
      background-color: ${({ theme }) => theme.colors.black};
    }
  }
  p {
    color: ${({ theme }) => theme.colors.darkGrey};
    font-size: ${({ theme }) => theme.fontSize.ml};
    font-weight: ${({ theme }) => theme.fontWeight.medium};
    line-height: 28px;
    margin: 20px 0;
    position: relative;
    &:after {
      position: absolute;
      content: "";
      width: 2px;
      height: 100%;
      right: -20px;
      top: 0%;
      background-color: ${({ theme }) => theme.colors.gold};
    }
    &:before {
      position: absolute;
      content: "";
      width: 50%;
      height: 2px;
      left: 25%;
      bottom: -100px;
      background-color: ${({ theme }) => theme.colors.black};
    }
  }
  @media screen and (max-width: 900px) {
    display: flex;
    flex-wrap: wrap-reverse;
    font-size: ${({ theme }) => theme.fontSize.ml};
    width: 85%;
    h3 {
      font-size: ${({ theme }) => theme.fontSize.l};
      font-weight: ${({ theme }) => theme.fontWeight.regular};
    }
    p {
      font-size: ${({ theme }) => theme.fontSize.ml};
      font-weight: ${({ theme }) => theme.fontWeight.regular};
    }
  }
`;

const MedWrapper = styled.div`
  width: 90%;
  max-width: 1100px;
  margin: 80px auto;
  padding-bottom: 80px;
  h3 {
    font-size: ${({ theme }) => theme.fontSize.xl};
    position: relative;
    &:before {
      position: absolute;
      content: "";
      width: 2px;
      height: 150%;
      left: -20px;
      top: -25%;
      background-color: ${({ theme }) => theme.colors.black};
    }
  }
  p {
    color: ${({ theme }) => theme.colors.darkGrey};
    font-size: ${({ theme }) => theme.fontSize.ml};
    font-weight: ${({ theme }) => theme.fontWeight.medium};
    line-height: 28px;
    margin: 20px 0;
  }
  @media screen and (max-width: 900px) {
    display: flex;
    flex-wrap: wrap-reverse;
    font-size: ${({ theme }) => theme.fontSize.ml};
    width: 85%;
    h3 {
      font-size: ${({ theme }) => theme.fontSize.l};
      font-weight: ${({ theme }) => theme.fontWeight.regular};
    }
    p {
      font-size: ${({ theme }) => theme.fontSize.ml};
      font-weight: ${({ theme }) => theme.fontWeight.regular};
    }
  }
`

export const PictureWrapper = styled.div`
  img {
    width: 100%;
  }
`;

const ServicesScopeIndex = () => (
  <MainTemplate>
    <Helmet>
      <title>Usługi - Adwokat Adrianna Firlej-Koniecka</title>
    </Helmet>
    <Background>
      <About>
        Kancelaria Adwokacja Adwokat Adrianny Firlej-Konieckiej świadczy kompleksowe usługi
        prawne we wszystkich dziedzinach prawa głównych specjalizacji, w
        szczególności:
      </About>
      <Service>
        <OnePost>
          {serviceDetailL.map((service) => (
            <ServiceDetailPost {...service} key={service.id} />
          ))}
        </OnePost>
        <OnePost>
          {serviceDetailR.map((service) => (
            <ServiceDetailPost {...service} key={service.id} />
          ))}
        </OnePost>
      </Service>
      <MedWrapper id="mediacje">
        <div>
          <h3>
            Mediacje
          </h3>
          <p>
            Mediacja to jeden ze sposobów rozwiązywania sporów, polegająca na działaniu osoby trzeciej- mediatora, którego zadaniem jest pomoc stronom w wypracowaniu kompromisowego rozwiązania. Jest to dobrowolna, poufna metoda rozwiązywania sporu, w której strony konfliktu lub sporu, z pomocą bezstronnego i neutralnego mediatora, samodzielnie dochodzą do porozumienia.
          </p>
          <p>
            Kancelaria prowadzi postępowania mediacyjne w szczególności w zakresie:
            <ul>
              <li>sporów cywilnych (spadkowe, majątkowe, o zapłatę, podział majątku, dział spadku, spory sąsiedzkie, spory przy realizacji umów, odszkodowania)</li>
              <li>sporów gospodarczych ( o zapłatę pomiędzy przedsiębiorcami, spory przy realizacji i negocjacjach umów handlowych)</li>
              <li>sporów rodzinnych (alimenty, kontakty z dziećmi, spory dotyczące władzy rodzicielskiej, uzgodnienie planu wychowawczego)</li>
            </ul>
          </p>
          <p>Należy ustalić z mediatorem termin i miejsce spotkania, a także kwestie kosztów postępowania mediacyjnego.</p>
          <p>W razie zawarcia ugody może zostać ona złożona w sądzie celem jej zatwierdzenia. Ugoda zawarta przed mediatorem, a następnie zatwierdzona przez Sąd jest równoważna wyrokowi sądowemu i może być podstawą do wszczęcia egzekucji.</p>
        </div>
      </MedWrapper>
      <Wrapper>
        <div>
          <h3>Jak przygotować się do spotkania z Adwokatem?</h3>
          <p>
            Spotkanie w kancelarii wymaga wcześniejszego ustalenia dogodnego dla
            obu stron terminu. Umawiając wizytę możecie być Państwo pewni, że
            adwokat przeznaczy dla Państwa odpowiednią ilość czasu oraz zachowa
            dyskrecję. Proszę o przygotowanie wszelkiej dokumentacji oraz
            informacji, które mogą mieć znaczenie dla sprawy. Porada prawna oraz
            analiza akt sprawy jest odpłatna.
          </p>
          <ContactItems isMobile isSmall />
        </div>
        <PictureWrapper>
          <img src={photo} alt={photo} />
        </PictureWrapper>
      </Wrapper>
    </Background>
  </MainTemplate>
);

export default ServicesScopeIndex;
